.App {
  max-width: 800px;
  min-width: 300px;
  margin: auto;
  text-align: center;
}

.header {

  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

.header h1 {
  font-size: calc(10px + 3vmin);
  padding: 5px;
}

.header p {
  font-size: calc(10px + 1vmin);
  padding-left: 10px;
  padding-right: 10px;
}

.footer {
  background-color: #282c34;
  min-height: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  margin-top: 10px;
}


.footer p {
  font-size: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.footer a {
  color: white;
}

.MuiCard-root {
  margin-top: 10px;
}

.method {
    text-align: left;
    font-size: 10px;
}

.compra {
  color: #20B1DF;
}

.aluguel {
  color: #DF4E20;
}