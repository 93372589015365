

.chart {
  width: 50%;
  float: left;
  color-scheme: only light;
}

.chartMobile {
  width: 100%;
  margin: auto;
  color-scheme: only light;
}

.panel {
  width: 50%;
  float: left;
}

.panelMobile {
  width: 100%;
  margin: auto;
}


.despesa {
  text-align: left;
  font-weight: bold;
}

.summaryContainer {
  text-align: left;
  padding: 20px;
}

.compra {
  color: #20B1DF;
}

.aluguel {
  color: #DF4E20;
}

.red {
  font-weight: bold;
}

.green {
  font-weight: bold;
}