
.inputContainer {
  display: flex;
  align-items: flex-start;
}

.wrap {
  flex-direction: column;
}

.inputColumn {
  flex: 1;
  text-align: left;
  margin-left: 60px;
}

.withMargin {
  width: 80%;
  margin-left: auto;
  margin-right: auto;

}
.buttonsContainer {
  text-align: left;
  margin-left: 60px;
  margin-top: 16px;
}

